<template>
  <div class="block">
    <el-page-header @back="dynamic" content="艺术家信息" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-button type="text">
        <el-button size="mini" type="primary" disabled @click="addPeo()">新增</el-button>
        <el-button size="mini" type="primary" @click="getPeopleData">全部显示</el-button>
        <el-select v-model="value" clearable placeholder="选择模块" size="mini"
                   style="margin-left: 20px; width: 130px"
                   @change="selectModular" @clear="getPeopleData">
          <el-option
              v-for="item in peoType"
              :key="item.value"
              :label="item.dictionaryName"
              :value="item.value"
          >
            <span>{{ item.dictionaryName }}</span>
          </el-option>
        </el-select>
      </el-button>
    </template>
    <el-table
        :data="peopleData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}"
        min-height="456">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="peoName"
          label="姓名">
        <template slot-scope="scopePeo">
          <el-link @click="getPeoById(scopePeo.row.peoId)">{{ scopePeo.row.peoName}}</el-link>
        </template>
      </el-table-column>
      <el-table-column
          prop="isIndex"
          label="显示"
          width="50">
        <template slot-scope="scopeIndex">
          <el-tag v-if="(scopeIndex.row.isIndex === 0)"  type="danger" size="mini">模块</el-tag>
          <el-tag v-if="(scopeIndex.row.isIndex === 1)" size="mini">首页</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="hits"
          label="浏览量"
          width="70">
      </el-table-column>
      <el-table-column
          prop="peoClass"
          label="模块"
          width="100">
        <template slot-scope="scopeOpen">
          <el-tag v-if="(scopeOpen.row.peoClass === 1)" size="mini" type="danger">学术委员会</el-tag>
          <el-tag v-if="(scopeOpen.row.peoClass === 2)" type="success" size="mini">中心艺术家</el-tag>
          <el-tag v-if="(scopeOpen.row.peoClass === 3)" type="info" size="mini">中心领导</el-tag>
          <el-tag v-if="(scopeOpen.row.peoClass === 4)" type="primary" size="mini">秘书处</el-tag>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="peoCheck"-->
<!--          label="预留"-->
<!--          width="150">-->
<!--      </el-table-column>-->
      <el-table-column
          prop="peoCreate"
          label="创建日期"
          width="160">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scopePeoId">
          <el-button disabled
              @click.native.prevent="delPeo(scopePeoId.row.peoId)"
              style="padding: 2px;">
            <i class="el-icon-delete"></i>
          </el-button>
          <el-button
              @click.native.prevent="editPeoById(scopePeoId.row.peoId)"
              style="padding: 2px; margin: 5px">
            <i class="el-icon-edit"></i>
          </el-button>
          <el-tooltip class="item" effect="dark" content="上传照片" placement="top-start">
            <el-button
                @click.native.prevent="queryEmpById(scopePeoId.row.peoId);"
                style="padding: 2px; margin: 5px 0px">
              <i class="el-icon-upload"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: "people",
  data() {
    return {
      editorOption: {},
      peopleData: [],
      peoType: [],
      value: ''
    }
  },
  mounted() {
    this.getPeopleData()
    this.getEditDictionaryByModular(3)
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },
    addPeo() {
      this.$router.push("peoEditer")
    },
    editPeoById(peoId) {
      this.$router.push({
        path: 'peoEditer',
        query: {peoId: peoId}
      })
    },
    getPeoById(peoId) {
      this.$router.push({
        path: '/peoReader/',
        query: {peoId: peoId}
      })
    },
    getPeopleData() {
      this.$http({
        method: 'get',
        url: '/api/people/getPeoList',
      }).then((res)=>{
        this.peopleData = res.data.data
      })
    },
    delPeo(peoId) {
      this.$confirm('删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          method: 'delete',
          url: '/api/people/delPeoById/' +peoId
        }).then((res) =>{
          this.$message({
            type: 'success',
            message: res.data.msg
          });
          this.getPeopleData()
        }).catch((res) =>{
          console.log(res)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      });
    },
    getEditDictionaryByModular (editModular) {
      this.$http({
        method: 'get',
        url: '/api/dictionary/getDictionaryByModular/' + editModular
      }).then((res) =>{
        this.peoType =res.data.data
      })
    },
    selectModular(modular) {
      if (modular === "") return;
      this.$http({
        method: 'get',
        url: '/api/people/getPeoListByModular/' + modular
      }).then((res) =>{
        this.peopleData = res.data.data
      })
    }
  }
};
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}

</style>